import moment from "moment"
import "moment/locale/es" // Importa el idioma español
import "moment/locale/en-gb" // Ejemplo: inglés de Reino Unido
import "moment/locale/fr" // Francés
// Importa otros idiomas que necesites

// Detecta el idioma del navegador
const detectLocale = () => {
    const userLocale = navigator.language || "en" // Idioma del navegador (e.g., 'es-ES', 'en-GB')
    const locale = userLocale.split("-")[0] // Tomamos solo el código base (e.g., 'es', 'en')
    return locale
}

// Configura el idioma de Moment.js
moment.locale(detectLocale())

// Helper para formatear una fecha
const formatDate = (date, format = "LL") => {
    // Verifica si la fecha es válida
    if (!moment(date).isValid()) {
        return "Fecha inválida" // Mensaje en caso de errores
    }
    return moment(date).format(format) // Formato de fecha
}

// Helper para mostrar tiempos relativos
const fromNow = (date) => {
    if (!moment(date).isValid()) {
        return "Fecha inválida"
    }
    return moment(date).fromNow() // Devuelve textos como "hace 3 días"
}

// Helper para mostrar tiempos relativos en mayúsculas
const fromNowCapitalize = (date) => {
    if (!moment(date).isValid()) {
        return "Fecha inválida"
    }
    return capitalize(moment(date).fromNow()) // Devuelve textos como "Hace 3 días"
}

// Helper para cambiar dinámicamente el idioma en tiempo real
const setLocale = (newLocale) => {
    moment.locale(newLocale)
}

// Función para capitalizar la primera letra
const capitalize = (text) => {
    if (!text) return ""
    return text.charAt(0).toUpperCase() + text.slice(1)
}

export { moment, formatDate, fromNow, fromNowCapitalize, setLocale }
