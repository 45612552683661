import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'

import GroupsService from 'services/GroupsService'
import MissionsService from 'services/MissionsService'
import EntitiesService from 'services/EntitiesService'

import SearchSelect from "components/form/SearchSelect"

const MissionModal = ({ show, modalType, mission, callBack }) => {

    const [confirmModalShow, setConfirmModalShow] = useState(false)
    const handleConfirmModalClose = (update) => {
        // update true for callback
        setConfirmModalShow(false)
        callBack(update)
    }
    const handleConfirmModalShow = () => setConfirmModalShow(true)

    const [name, setName] = useState(mission.name)
    const [description, setDescription] = useState(mission.description)
    const [usersIds, setUsersIds] = useState(mission.users)
    const [entitiesUsers, setEntitiesUsers] = useState([])
    const [entitiesIds, setEntitiesIds] = useState([])
    const [entities, setEntities] = useState([])
    const [formValid, setFormValid] = useState(false)

    const getEntitiesGroup = async (groupId) => {
        if (!groupId) return

        try {
            const res = await GroupsService.getGroupEntities(groupId)
            setEntities(res.data.entities)
        } catch (error) {
            console.error("Error fetching group entities", error)
        }
    }

    const getEntityUsers = async (entityId) => {
        if (!entityId) return

        try {
            const res = await EntitiesService.getEntityUsers(entityId)
            const newUsers = res.data.users

            setEntitiesUsers(prevUsers => [
                ...prevUsers,
                ...newUsers.filter(user => !prevUsers.some(prev => prev._id === user._id))
            ])
        } catch (error) {
            console.error("Error fetching entity users:", error)
        }
    }

    const handleNameInput = (e) => {
        setName(e.target.value)
    }

    const handleDescriptionInput = (e) => {
        setDescription(e.target.value)
    }

    const handleEntityCheckBox = (e) => {
        if (e.target.checked) {
            const valueToAdd = e.target.name
            getEntityUsers(valueToAdd) // e.target.name come with entity._id
            setEntitiesIds(prevEntitiesIds => [...prevEntitiesIds, valueToAdd])
        } else {
            const valueToRemove = e.target.name
            let newArray = []
            for (var i = 0; i < entitiesUsers.length; i++) {
                if (entitiesUsers[i].entityId != valueToRemove) {
                    newArray.push(entitiesUsers[i])
                }
            }
            setEntitiesUsers(newArray)
            setEntitiesIds(prevEntitiesIds => prevEntitiesIds.filter(item => item !== valueToRemove))
        }
    }

    const handleSelectUsersChange = (e) => {
        //const selectedOptions = Array.from(e.target.selectedOptions, option => option.value)
        setUsersIds(e.target.value)
    }

    const handleUpdateBtn = async () => {
        await updateMission(mission._id)
        handleConfirmModalClose(true)
    }

    const updateMission = async (missionId) => {
        const update = { name: name, description: description }
        await MissionsService.updateMission(update, missionId)
        setFormValid(false)
    }

    const handleDeleteBtn = async () => {
        await deleteMission(mission._id)
        handleConfirmModalClose(true)
    }

    const deleteMission = async (missionId) => {
        await MissionsService.deleteMission(missionId)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (form.checkValidity()) {
            const update = { name: name, description: description, users: usersIds }
            await MissionsService.updateMission(update, mission._id)
            handleConfirmModalClose(true)
            return
        }

        setFormValid(true)
    }

    const getModalTitle = (type) => {
        switch (type) {
            case 'edit':
                return <><i className="bi bi-pencil-square"></i> <FormattedMessage id="missionsEdit" defaultMessage="Edit mission" /></>
            case 'delete':
                return <><i className="bi bi-trash"></i> <FormattedMessage id="missionsDelete" defaultMessage="Delete mission?" /></>
            default:
                return <><i className="bi bi-pencil-square"></i> <FormattedMessage id="missionsEdit" defaultMessage="Edit mission" /></>
        }
    }

    useEffect(() => {
        getEntitiesGroup(mission.groupId)
    }, [])

    useEffect(() => {
        if (show) {
            handleConfirmModalShow()
        } else {
            handleConfirmModalClose(false)
        }
    }, [show])

    useEffect(() => {
        if (!mission) return
        const distinctEntityIds = mission?.usersInfo?.map(user => user.entityId) // Extrae los entityId
            .filter((id, index, array) => array.indexOf(id) === index) // Elimina duplicados
        setEntitiesIds(distinctEntityIds)
    }, [mission])

    useEffect(() => {
        entitiesIds.map(entity => {
            getEntityUsers(entity)
        })
    }, [entitiesIds])

    return (
        <Modal
            show={confirmModalShow}
            onHide={() => handleConfirmModalClose(false)}
            animation={false}
            backdrop={"static"}
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{getModalTitle(modalType)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>
                    <b><FormattedMessage id="missionsName" defaultMessage="Mission name" />: </b> {name}
                </h5>
                {modalType === 'edit' && (
                    <Form noValidate validated={formValid} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label><FormattedMessage id="missionsName" defaultMessage="Mission name" />:</Form.Label>
                            <Form.Control
                                type="text"
                                value={name}
                                name="missionName"
                                onChange={handleNameInput}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="missionsNameInvalid" defaultMessage="Provide a mission name" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="entities">
                            <Form.Label><FormattedMessage id="missionsEntities" defaultMessage="Entities" />:</Form.Label>
                            <div key="inline-radio-entities" className="mb-3">
                                {entities && entities.map((entity) => {
                                    return (
                                        <Form.Check
                                            inline
                                            label={entity.name}
                                            name={entity._id}
                                            type="switch"
                                            id={`taskEntity-${entity._id}`}
                                            key={`taskEntity-${entity._id}`}
                                            onChange={handleEntityCheckBox}
                                            checked={entitiesIds.includes(entity._id)}
                                            aria-checked={entitiesIds.includes(entity._id)}
                                        />
                                    )
                                }
                                )}
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="usersIds">
                            <Form.Label><FormattedMessage id="groupsUsers" defaultMessage="Users" />:</Form.Label>
                            <SearchSelect
                                fieldId={'_id'}
                                fieldName={'usersIds'}
                                selected={usersIds}
                                onChange={handleSelectUsersChange}
                                options={entitiesUsers
                                    .filter(user => user.roles.includes("ROLE_INSPECTOR"))
                                }
                                placeholder={'groupsUsersSelect'}
                                required
                                isInvalid={formValid}
                                multiple
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="groupsUsersInvalid" defaultMessage="Select at least one user" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label><FormattedMessage id="missionsDescription" defaultMessage="Description" />:</Form.Label>
                            <Form.Control as="textarea" rows={3} value={description} onChange={handleDescriptionInput} />
                        </Form.Group>

                        <div className="d-grid gap-2">
                            <Button type="submit" variant="primary">
                                <i className="bi bi-pencil-square"></i> <FormattedMessage id="textUpdate" defaultMessage="Update" />
                            </Button>
                        </div>
                    </Form>
                )}

                {modalType === 'delete' && (
                    <>
                        <p>{description}</p>

                        <Alert variant={'danger'}>
                            <FormattedMessage
                                id="missionsDeleteAdvise"
                                defaultMessage="This action will delete the mission and its <strong>[all] {numTasks} tasks!</strong>"
                                values={{ numTasks: mission.features.length, strong: chunks => <strong>{chunks}</strong> }}
                            />
                        </Alert>
                    </>
                )}

            </Modal.Body>
            <Modal.Footer>
                {modalType === 'edit' && (
                    <Button variant="secondary" onClick={() => handleConfirmModalClose(false)}>
                        <i className="bi bi-x-square"></i> <FormattedMessage id="textClose" defaultMessage="Close" />
                    </Button>
                )}
                {modalType === 'delete' && (
                    <>
                        <Button variant="danger" onClick={handleDeleteBtn}>
                            <i className="bi bi-trash"></i> <FormattedMessage id="textYes" defaultMessage="Yes" />
                        </Button>
                        <Button variant="secondary" onClick={() => handleConfirmModalClose(false)}>
                            <i className="bi bi-x-square"></i> <FormattedMessage id="textNo" defaultMessage="No" />
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default MissionModal