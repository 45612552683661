import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class RolesService {

  // Get roles list
  getRoles = async () => {
    return await axios.get(API_URL + '/roles', { headers: authHeader() })
  }

  // Get role data
  getRole = async (id) => {
    return await axios.get(API_URL + '/roles/' + id, { headers: authHeader() })
  }

}

export default new RolesService()