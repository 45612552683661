import React from "react"
import { FormattedMessage } from "react-intl"
import DataTable from "react-data-table-component"  // DOC https://react-data-table-component.netlify.app/?path=/docs/getting-started-intro--docs
import Button from "react-bootstrap/Button"
import Badge from 'react-bootstrap/Badge'

import { PencilSquare, Trash } from "react-bootstrap-icons" // Iconos de Bootstrap

const TableManagement = ({ icon, title, columns, data, paginationPerPage, paginationTotalRows, onChangePage, onChangeRowsPerPage, onSort, onActionClick }) => {

    const columnsWithButtons = [
        ...columns,
        {
            id: 'buttons',
            name: <FormattedMessage id="textActions" defaultMessage="Actions" />,
            cell: (row) => {
                return (
                    <>
                        <Button onClick={() => onActionClick({ data: row, type: 'edit' })} variant="primary" size="sm">
                            <PencilSquare /> <FormattedMessage id="textEdit" defaultMessage="Edit" />
                        </Button>&nbsp;
                        <Button onClick={() => onActionClick({ data: row, type: 'delete' })} variant="danger" size="sm">
                            <Trash /> <FormattedMessage id="textDelete" defaultMessage="Delete" />
                        </Button>
                    </>
                )
            },
            ignoreRowClick: true,
        }
    ]

    return (
        <DataTable
            title={<><Badge bg="light" text="dark">{icon ? icon : ''} {paginationTotalRows} {title}</Badge></>}
            columns={columnsWithButtons}
            data={data}
            noDataComponent={<FormattedMessage id="textNoRecords" defaultMessage="There are no records to display" />}
            pagination
            paginationServer
            paginationPerPage={paginationPerPage}
            paginationTotalRows={paginationTotalRows}
            paginationComponentOptions={{
                rowsPerPageText: <FormattedMessage id="textRowsPerPage" defaultMessage="Rows per page" />
            }}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onSort={onSort}
            sortServer
            highlightOnHover
            striped
        />
    )
}

export default TableManagement