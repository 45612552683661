import React from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import ListGroup from "react-bootstrap/ListGroup"
import { ThreeDotsVertical } from "react-bootstrap-icons"

const Overlay = ({ data }) => {
    return (
        <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="right"
            overlay={
                <Popover id={`tr-popover`}>
                    <Popover.Body>
                        <ListGroup variant="flush">
                            {data.map((val, index) => (
                                <ListGroup.Item key={`tr-popover-${index}-${typeof val === 'object' && val.name ? val.name : val}`}>{typeof val === 'object' && val.name ? val.name : val}</ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Popover.Body>
                </Popover>
            }
        >
            <span>{data.length} <ThreeDotsVertical/></span>
        </OverlayTrigger>
    )
}

export default Overlay
