import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class EntitiesService {

  // Get entities list
  getEntities = async (pageNum, search = '', limit = false, sortBy = 'name', sortOrder = 'asc', skillId = '') => {
    return await axios.get(API_URL + '/entities?page=' + pageNum + '&search=' + search + '&limit=' + limit + '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + '&skillId=' + skillId, { headers: authHeader() })
  }

  // Add new entity
  addEntity = async (entity) => {
    return await axios.post(API_URL + '/entities', entity, { headers: authHeader() })
  }

  // Update entity data
  updateEntity = async (entity, id) => {
    return await axios.patch(API_URL + '/entities/' + id, entity, { headers: authHeader() })
  }

  // Get entity data
  getEntity = async (id) => {
    return await axios.get(API_URL + '/entities/' + id, { headers: authHeader() })
  }

  // Get entity users
  getEntityUsers = async (id) => {
    return await axios.get(API_URL + '/entities/' + id + '/users', { headers: authHeader() })
  }

  // Delete entity 
  deleteEntity = async (id) => {
    return await axios.delete(API_URL + '/entities/' + id, { headers: authHeader() })
  }

}

export default new EntitiesService()