import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class GroupsService {

  // Get groups list
  getGroups = async (pageNum, search = '', limit = false, sortBy = 'name', sortOrder = 'asc', skillId = '', entityId = '') => {
    return await axios.get(API_URL + '/groups?page=' + pageNum + '&search=' + search + '&limit=' + limit + '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + '&skillId=' + skillId + '&entityId=' + entityId, { headers: authHeader() })
  }

  // Add new group
  addGroup = async (group) => {
    return await axios.post(API_URL + '/groups', group, { headers: authHeader() })
  }

  // Update group data
  updateGroup = async (group, id) => {
    return await axios.patch(API_URL + '/groups/' + id, group, { headers: authHeader() })
  }

  // Get group data
  getGroup = async (id, populate = false) => {
    return await axios.get(API_URL + '/groups/' + id + '?populate=' + populate, { headers: authHeader() })
  }

  // Delete group 
  deleteGroup = async (id) => {
    return await axios.delete(API_URL + '/groups/' + id, { headers: authHeader() })
  }

  // Get group :id with entities list
  getGroupEntities = async (id) => {
    return await axios.get(API_URL + '/groups/' + id + '/entities', { headers: authHeader() })
  }

  // Get missions list from group :id in GEOJson format
  getGroupGeoJSON = async (id) => {
    return await axios.get(API_URL + '/groups/' + id + '/geojson', { headers: authHeader() })
  }

}

export default new GroupsService()