import React, { useState, useEffect } from "react"
import { useIntl, FormattedMessage } from "react-intl"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Search, PencilSquare, Trash, PlusCircle, Backspace, LayersFill } from "react-bootstrap-icons" // Iconos de Bootstrap

import { AlertError, AlertLoading } from '../helpers/AlertHelper'
import { fromNow } from "../helpers/momentHelper"

import FormModal from "../components/modals/FormModalNew"
import TableManagement from "../components/tables/TableManagement"

import ConfigService from '../services/ConfigService'
import LayersService from "../services/LayersService"

import LayersForm from "./LayersForm"

const Layers = () => {
  const intl = useIntl()

  const frontConfig = ConfigService.getFrontConfig()

  const [eventId, setLayerId] = useState(null)
  const [layers, setLayers] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [search, setSearch] = useState("")
  const [sortBy, setSortBy] = useState("name")
  const [sortOrder, setSortOrder] = useState("asc")
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(frontConfig.app.itemsListLimit)

  // Setting modal title
  const [modalTitle, setModalTitle] = useState(false)

  // Setting modal icon
  const [modalIcon, setModalIcon] = useState(false)

  // Open modal form control to add/edit element
  const [modalTrigger, setModalTrigger] = useState(false)

  // Setting modal action enum[add,edit,delete]
  const [modalAction, setModalAction] = useState(false)

  // Fetch data
  const fetchLayers = async () => {
    try {
      const response = await LayersService.getLayers({
        pageNum: currPage,
        type: false,
        search: search,
        limit: perPage,
        sortBy,
        sortOrder
      })
      setLayers(response.data.layers)
      setTotalRows(response.data.pagination.totalItems)
      setIsLoaded(true)
    } catch (err) {
      setIsLoaded(false)
      setError(err)
    }
  }

  const handleModal = () => {
    setModalTrigger(false)
    fetchLayers()
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
    setCurrPage(1) // Reinicia a la primera página cuando se busca
  }

  const handleSearchClean = () => {
    setSearch('')
    setCurrPage(1) // Reinicia a la primera página cuando se busca
  }

  // Re-fetch data on page/perPage change
  useEffect(() => {
    fetchLayers()
  }, [currPage, perPage, search, sortBy, sortOrder])

  const handleButtonClick = ({ data, type }) => {
    // Definir un objeto para mapear tipos a títulos e íconos
    const modalConfig = {
      add: {
        title: <FormattedMessage id="layersAdd" defaultMessage="Add event" />,
        icon: <PlusCircle />,
      },
      edit: {
        title: <FormattedMessage id="layersEdit" defaultMessage="Edit event" />,
        icon: <PencilSquare />,
      },
      delete: {
        title: <FormattedMessage id="layersDelete" defaultMessage="Delete event" />,
        icon: <Trash />,
      }
    }

    // Establecer la configuración del modal dependiendo del tipo
    const config = modalConfig[type] || {}

    if (config) {
      setLayerId(type === 'add' ? false : data._id) // Si 'add', eventid será falso
      setModalTitle(config.title) // Set title from the config
      setModalIcon(config.icon) // Set icon from the config
      setModalAction(type)
      setModalTrigger(true)
    }
  }

  const handleRowsPerPageChange = (newPerPage, page) => {
    setPerPage(newPerPage)
    setCurrPage(page)
  }

  const handleSort = async (column, sortDirection) => {
    setSortOrder(sortDirection)
    setSortBy(column.id)
    setCurrPage(1)
  }

  const columns = [
    {
      id: 'type',
      name: <FormattedMessage id="layersType" defaultMessage="Type" />,
      selector: (row) => row.type,
      sortable: true,
    },
    {
      id: 'name',
      name: <FormattedMessage id="layersName" defaultMessage="Name" />,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      id: 'featureIdInProperty',
      name: <FormattedMessage id="layersIdField" defaultMessage="Id field" />,
      selector: (row) => row.featureIdInProperty
    },
    {
      id: 'featureNameInProperty',
      name: <FormattedMessage id="layersNameField" defaultMessage="Name field" />,
      selector: (row) => row.featureNameInProperty
    },
    {
      id: 'createdAt',
      name: <FormattedMessage id="layersCreatedAt" defaultMessage="Created at" />,
      selector: (row) => fromNow(row.createdAt),
      sortable: true,
    },
    {
      id: 'active',
      name: <FormattedMessage id="layersActive" defaultMessage="Active" />,
      selector: (row) => row.active ? <FormattedMessage id="textYes" defaultMessage="Yes" /> : <FormattedMessage id="textNo" defaultMessage="No" />,
      sortable: true,
      right: true
    }

  ]

  return (
    <Container fluid className="p-0">
      <Row className="p-1 mt-3">
        <Col>
          <InputGroup>
            <InputGroup.Text><Search /></InputGroup.Text>
            <Form.Control
              type="text"
              placeholder={intl.formatMessage({ id: "textSearch", defaultMessage: "Search layers..." })}
              value={search}
              onChange={handleSearchChange}
            />
            <Button onClick={handleSearchClean} variant="light">
              <Backspace /> <FormattedMessage id="textClear" defaultMessage="Clear" />
            </Button>

            <Button onClick={() => handleButtonClick({ data: null, type: 'add' })} variant="primary">
              <PlusCircle /> <FormattedMessage id="textAdd" defaultMessage="Add" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      {isLoaded ? (
        <TableManagement
          icon={<LayersFill />}
          title={<FormattedMessage id="navMapLayers" defaultMessage="Map layers" />}
          columns={columns}
          data={layers}
          paginationPerPage={perPage}
          paginationTotalRows={totalRows}
          onChangePage={setCurrPage}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onSort={handleSort}
          onActionClick={handleButtonClick}
        />
      ) : error ? (
        <AlertError error={error} />
      ) : (
        <AlertLoading isLoaded={isLoaded} />
      )}

      <FormModal
        title={modalTitle}
        icon={modalIcon}
        body={LayersForm}
        refreshData={handleModal}
        modalTrigger={modalTrigger}
        elementId={eventId}
        action={modalAction} />

    </Container>
  )
}

export default Layers