import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import EntitiesService from '../../services/EntitiesService'
import MissionsService from '../../services/MissionsService'

import SearchSelect from "components/form/SearchSelect"

const TaskActionModal = ({ modal, toggle, selectedFeature, setModal, group, updateGroupData }) => {

    const { groupId } = useParams()  // :groupId param

    const [show, setShow] = useState(false)

    const handleClose = () => {
        setModal(false)
        setShow(false)
        setEntityUsers(false)
        updateGroupData(true)
    }
    const handleShow = () => setShow(true)

    const [entityUsers, setEntityUsers] = useState(false)

    const getEntityUsers = (entityId) => {
        if (entityId) {
            EntitiesService.getEntityUsers(entityId)
                .then(res => {
                    setEntityUsers(res.data.users)
                })
        }
    }

    const [taskName, setTaskName] = useState()
    const [taskDescription, setTaskDescription] = useState()
    const [taskInspector, setTaskInspector] = useState()
    const [formValid, setFormValid] = useState(false)

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {

        switch (fieldName) {
            case 'taskName':
                setTaskName(value)
                break
            case 'taskDescription':
                setTaskDescription(value)
                break
            case 'taskInspector':
                setTaskInspector(value)
                break
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (form.checkValidity()) {
            const mission = {
                name: taskName,
                description: taskDescription,
                groupId: group._id,
                feature: {
                    featureId: selectedFeature.id,
                    geometry: selectedFeature.geometry
                },
                layerId: selectedFeature.layerId,
                users: [taskInspector]
            }

            // Add mission
            MissionsService.addMission(mission)
                .then(res => {
                    setFormValid(false)
                    handleClose()
                })
            return
        }

        setFormValid(true)
    }

    useEffect(() => {
        setEntityUsers(false)
    }, [groupId])

    useEffect(() => {
        if (!modal) return
        if (toggle) {
            handleShow()
        }
    }, [toggle])

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop={"static"}
            keyboard={false}
            fullscreen={false}
            aria-labelledby={"geoj-modal-feature-create-task"}
            size={"lg"}
        >
            <Modal.Header closeButton>
                <Modal.Title id="geoj-modal-feature-create-task"><i className="bi bi-clipboard2-fill"></i> <FormattedMessage id="tasksCreate" defaultMessage="Create Task" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>
                    <b><FormattedMessage id="tasksElementName" defaultMessage="Element name" />: </b>
                    {selectedFeature &&
                        selectedFeature.properties &&
                        selectedFeature.properties.name}
                </h5>
                <Form noValidate validated={formValid} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="taskform.group.name">
                        <Form.Label><FormattedMessage id="tasksGroupName" defaultMessage="Group name" />:</Form.Label>
                        <Form.Control type="text" name="groupName" value={group.name} placeholder={group.name} disabled />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="taskform.task.name">
                        <Form.Label><FormattedMessage id="tasksName" defaultMessage="Task name" />:</Form.Label>
                        <Form.Control
                            type="text"
                            name="taskName"
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="tasksNameInvalid" defaultMessage="Please provide a task name" />
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="taskform.task.description">
                        <Form.Label><FormattedMessage id="tasksDescription" defaultMessage="Description" />:</Form.Label>
                        <Form.Control
                            name="taskDescription"
                            as="textarea"
                            rows={3}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="taskform.task.description">
                        <Form.Label><FormattedMessage id="tasksEntity" defaultMessage="Entity" />:</Form.Label>
                        <div key="inline-radio-entities" className="mb-3">
                            {group && group.entities && group.entities.map((entity, index) => {
                                return (
                                    <Form.Check
                                        inline
                                        label={entity.name}
                                        name={`taskEntity`}
                                        type="radio"
                                        id={`taskEntity-${entity._id}`}
                                        key={`taskEntity-${entity._id}`}
                                        onChange={() => getEntityUsers(entity._id)}
                                        required
                                        feedback={<FormattedMessage id="tasksEntityInvalid" defaultMessage="Select an entity to view the users" />}
                                        feedbackType='invalid'
                                    />
                                )
                            }
                            )}
                        </div>
                    </Form.Group>

                    {entityUsers &&
                        <Form.Group className="mb-3" controlId="taskform.task.userId">
                            <Form.Label><FormattedMessage id="tasksInspector" defaultMessage="Inspector" />:</Form.Label>
                            <SearchSelect
                                fieldId={'_id'}
                                fieldName={'taskInspector'}
                                selected={taskInspector}
                                onChange={handleInputChange}
                                options={entityUsers
                                    .filter(user => user.roles.includes("ROLE_INSPECTOR"))
                                }
                                placeholder={'usersNameSelect'}
                                required
                                isInvalid={formValid}
                            />                            
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="tasksInspectorInvalid" defaultMessage="Please select an inspector user" />
                            </Form.Control.Feedback>
                        </Form.Group>
                    }

                    <div className="d-grid gap-2">
                        <Button type="submit" variant="primary">
                            <i className="bi bi-clipboard-plus"></i> <FormattedMessage id="tasksCreate" defaultMessage="Create task" />
                        </Button>
                    </div>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    <i className="bi bi-x-square"></i> <FormattedMessage id="textCancel" defaultMessage="Cancel" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TaskActionModal

