import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class LayersService {

  // Get layers list
  getLayers = async ({pageNum = 1, type, search = '', limit = false, sortBy = 'name', sortOrder = 'asc'}) => {
    let url = API_URL + '/layers?'

    if (pageNum !== false) {
      url += 'page=' + pageNum + '&'
    }
    if (type !== false && type !== undefined) {
      url += 'type=' + type + '&'
    }
    if (search !== false) {
      url += 'search=' + search + '&'
    }
    if (limit !== false) {
      url += 'limit=' + limit + '&'
    }

    url += 'sortBy=' + sortBy + '&sortOrder=' + sortOrder

    return await axios.get(url, { headers: authHeader() })
  }

  // Add new layer
  addLayer = async (layer) => {
    return await axios.post(API_URL + '/layers', layer, { headers: authHeader() })
  }

  // Update layer data
  updateLayer = async (layer, id) => {
    return await axios.patch(API_URL + '/layers/' + id, layer, { headers: authHeader() })
  }

  // Get layer data
  getLayer = async (id) => {
    return await axios.get(API_URL + '/layers/' + id, { headers: authHeader() })
  }

  // Delete layer 
  deleteLayer = async (id) => {
    return await axios.delete(API_URL + '/layers/' + id, { headers: authHeader() })
  }

  // Upload file
  uploadFile = async (id, layerFile, onUploadProgress) => {
    return await axios.post(API_URL + '/layers/' + id + '/upload', layerFile, { headers: authHeader(), onUploadProgress })
  }

  // Download file GeoJSON
  geojson = async (id) => {
    return await axios.get(API_URL + '/layers/' + id + '/export', { headers: authHeader() })
  }

  // Download file GeoJSON in BBOX
  bbox = async (id, lonbl, latbl, lonur, latur) => {
    return await axios.get(API_URL + '/layers/' + id + '/bbox?lonbl='+lonbl+'&latbl='+latbl+'&lonur='+lonur+'&latur='+latur, { headers: authHeader() })
  }
}

export default new LayersService()