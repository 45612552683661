import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import SearchSelect from "components/form/SearchSelect"

import GroupsService from '../services/GroupsService'
import EntitiesService from '../services/EntitiesService'
import FormsService from '../services/FormsService'
import EventsService from '../services/EventsService'
import LayersService from '../services/LayersService'

import { AlertMsg, AlertError, AlertLoading } from 'helpers/AlertHelper'

function GroupsForm(props) {
    const [elementId, setElementId] = useState(props.elementId)
    const [action, setAction] = useState(props.action)
    const [filterActive, setFilterActive] = useState(props.filterActive) || false
    const [name, setName] = useState('')
    const [skill, setSkill] = useState('')
    const [eventId, setEventId] = useState('')
    const [formId, setFormId] = useState('')
    const [drawLayerId, setDrawLayerId] = useState('')
    const [entitiesIds, setEntitiesIds] = useState([])
    const [entitiesSkills, setEntitiesSkills] = useState([])
    const [description, setDescription] = useState('')
    const [entities, setEntities] = useState([])
    const [events, setEvents] = useState([])
    const [forms, setForms] = useState([])
    const [layers, setLayers] = useState([])
    const [buttonTitle, setButtonTitle] = useState('')
    const [inputDisabled, setInputDisabled] = useState(false)
    const [formValid, setFormValid] = useState(false)

    const [showMsg, setShowMsg] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)

    const getEntitiesData = async () => {
        await EntitiesService.getEntities(1, '', 100).then(
            response => {
                setEntities(response.data.entities)
                setEntitiesSkills(Array.from(new Set(response.data.entities.flatMap(entity => entity.skills))).map(skill => ({
                    id: skill,
                    name: skill,
                })))
            },
            error => {
                setError(error)
            })
    }

    const getEvents = async () => {
        if (!filterActive) {
            await EventsService.getEvents(1, '', 100).then(
                response => {
                    setEvents(response.data.events)
                },
                error => {
                    setError(error)
                })
        } else {
            await EventsService.getActiveEvents(1, '', 100).then(
                response => {
                    setEvents(response.data.events)
                },
                error => {
                    setError(error)
                })
        }
    }

    const getForms = async () => {
        await FormsService.getForms(1, '', 100).then(
            response => {
                setForms(response.data.forms)
            },
            error => {
                setError(error)
            })
    }

    const getLayers = async () => {
        await LayersService.getLayers({ pageNum: 1, type: 'DRAWED', limit: 100 }).then(
            response => {
                setLayers(response.data.layers)
            },
            error => {
                setError(error)
            })
    }

    const getData = async () => {
        await GroupsService.getGroup(elementId).then(
            response => {
                setName(response.data.name || '')
                setSkill(response.data.skill || '')
                setDrawLayerId(response.data.drawLayerId || '')
                setEventId(response.data.eventId || '')
                setFormId(response.data.formId || '')
                setEntitiesIds(response.data.entitiesIds || [])
                setDescription(response.data.description || '')
                setIsLoaded(true)
            },
            error => {
                setError(error)
            })
    }

    useEffect(() => {

        getEntitiesData()

        getEvents()

        getForms()

        getLayers()

        if (elementId) {
            getData()
        }

        switch (action) {
            case 'edit':
                setButtonTitle(<FormattedMessage id="textSave" defaultMessage="Save" />)
                break
            case 'delete':
                setButtonTitle(<FormattedMessage id="textDelete" defaultMessage="Delete" />)
                setInputDisabled(true)
                break
            default:
                setButtonTitle(<FormattedMessage id="textAdd" defaultMessage="Add" />)
        }

    }, [])

    const onSubmit = () => {
        props.onSubmit()
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {
        switch (fieldName) {
            case 'name':
                setName(value)
                break
            case 'skill':
                setSkill(value)
                setEntitiesIds([])
                break
            case 'drawLayerId':
                setDrawLayerId(value)
                break
            case 'eventId':
                setEventId(value)
                break
            case 'formId':
                setFormId(value)
                break
            case 'entitiesIds':
                setEntitiesIds(value)
                break
            case 'description':
                setDescription(value)
                break
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (!form.checkValidity()) {
            setFormValid(true)
            return
        }

        const group = {
            name,
            skill,
            eventId,
            formId,
            drawLayerId,
            entitiesIds,
            description,
        }

        try {
            let response

            if (elementId && action === "edit") {
                response = await GroupsService.updateGroup(group, elementId)
            } else if (action === "add") {
                response = await GroupsService.addGroup(group)
            } else if (action === "delete") {
                response = await GroupsService.deleteGroup(elementId)
            }

            if (response) {
                setFormValid(false)
                onSubmit()
            }
        } catch (error) {
            setShowMsg({ text: error, style: 'danger' })
            setShowAlert(true)
        } finally {
            setFormValid(true)
        }
    }

    if (error) {
        return (
            <AlertError error={error} />
        )
    } else if (elementId && !isLoaded) {
        return (
            <AlertLoading isLoaded={isLoaded} />
        )
    }

    return (
        <div>
            <Form noValidate validated={formValid} onSubmit={handleSubmit}>
                {elementId && (
                    <Form.Group className="mb-3" controlId="id">
                        <Form.Label>Id:</Form.Label>
                        <Form.Control
                            type="text"
                            name="id"
                            value={elementId}
                            disabled={"disabled"}
                        />
                    </Form.Group>
                )}

                <Form.Group className="mb-3" controlId="name">
                    <Form.Label><FormattedMessage id="groupsName" defaultMessage="Name" />:</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={name}
                        placeholder='Group name'
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="groupsNameInvalid" defaultMessage="Provide a group name" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="skill">
                    <Form.Label><FormattedMessage id="groupsSkill" defaultMessage="Skill" />:</Form.Label>
                    <SearchSelect
                        fieldName={'skill'}
                        selected={skill}
                        onChange={handleInputChange}
                        options={entitiesSkills}
                        placeholder={'entitiesSkillSelect'}
                        disabled={inputDisabled}
                        required
                        isInvalid={formValid}
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="groupsSkillInvalid" defaultMessage="Select a group skill" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="drawLayerId">
                    <Form.Label><FormattedMessage id="groupsDrawLayer" defaultMessage="Draw layer" />:</Form.Label>
                    <SearchSelect
                        fieldId={'_id'}
                        fieldName={'drawLayerId'}
                        selected={drawLayerId}
                        onChange={handleInputChange}
                        options={layers}
                        placeholder={'groupsDrawLayerSelect'}
                        disabled={inputDisabled}
                        required
                        isInvalid={formValid}
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="groupsDrawLayerInvalid" defaultMessage="Select a draw layer" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="eventId">
                    <Form.Label><FormattedMessage id="groupsEvent" defaultMessage="Event" />:</Form.Label>
                    <SearchSelect
                        fieldId={'_id'}
                        fieldName={'eventId'}
                        selected={eventId}
                        onChange={handleInputChange}
                        options={events}
                        placeholder={'groupsEventSelect'}
                        disabled={inputDisabled}
                        required
                        isInvalid={formValid}
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="groupsEventInvalid" defaultMessage="Select an event" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formId">
                    <Form.Label><FormattedMessage id="groupsForm" defaultMessage="Form" />:</Form.Label>
                    <SearchSelect
                        fieldId={'_id'}
                        fieldName={'formId'}
                        labelKey={'title'}
                        selected={formId}
                        onChange={handleInputChange}
                        options={forms.filter(form => form.skill === skill)}
                        placeholder={'groupsFormSelect'}
                        disabled={inputDisabled}
                        required
                        isInvalid={formValid}

                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="groupsFormInvalid" defaultMessage="Select a form" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="entitiesIds">
                    <Form.Label><FormattedMessage id="groupsEntities" defaultMessage="Entities" />:</Form.Label>
                    <SearchSelect
                        fieldId={'_id'}
                        fieldName={'entitiesIds'}
                        selected={entitiesIds}
                        onChange={handleInputChange}
                        options={entities.filter(entity => entity.skills.includes(skill))}
                        placeholder={'groupsEntitiesSelect'}
                        disabled={inputDisabled}
                        required
                        isInvalid={formValid}
                        multiple
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="groupsEntitiesInvalid" defaultMessage="Select at least one entity" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="description">
                    <Form.Label><FormattedMessage id="groupsDescription" defaultMessage="Description" />:</Form.Label>
                    <Form.Control
                        name="description"
                        value={description}
                        as="textarea"
                        disabled={inputDisabled && ("disabled")}
                        rows={3}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <AlertMsg message={showMsg.text} variant={showMsg.style} dismissible onCloseHandler={() => setShowAlert(false)} show={showAlert} />

                <div className="d-grid gap-2">
                    {action === "add" && (
                        <Button
                            className="btn btn-dark"
                            type="submit"
                        >
                            <i className="bi bi-plus-circle"></i> {buttonTitle}
                        </Button>
                    )}

                    {action === "edit" && (
                        <Button
                            className="btn btn-primary"
                            type="submit"
                        >
                            <i className="bi bi-save2"></i> {buttonTitle}
                        </Button>
                    )}

                    {action === "delete" && (
                        <Button
                            className="btn btn-danger"
                            type="submit"
                        >
                            <i className="bi bi-trash"></i> {buttonTitle}
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    )

}

export default GroupsForm