import React, { useState, useEffect } from "react"
import { useIntl, FormattedMessage } from "react-intl"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Search, PencilSquare, Trash, PlusCircle, Backspace, Diagram3Fill, Stars, BriefcaseFill } from "react-bootstrap-icons" // Iconos de Bootstrap

import { AlertError, AlertLoading } from '../helpers/AlertHelper'
import { fromNow } from "../helpers/momentHelper"

import FormModal from "../components/modals/FormModalNew"
import TableManagement from "../components/tables/TableManagement"
import Overlay from "../components/overlays/Overlay"
import SearchSelect from "components/form/SearchSelect"

import ConfigService from '../services/ConfigService'
import GroupsService from "../services/GroupsService"
import EntitiesService from "services/EntitiesService"

import GroupsForm from "./GroupsForm"

import { ENTITY_SKILLS } from "data/entities"

const Groups = ({ updateFunction }) => {
  const intl = useIntl()

  const frontConfig = ConfigService.getFrontConfig()

  const [groupId, setGroupId] = useState(null)
  const [groups, setGroups] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [search, setSearch] = useState("")
  const [sortBy, setSortBy] = useState("name")
  const [sortOrder, setSortOrder] = useState("asc")
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(frontConfig.app.itemsListLimit)

  const [skillsList, setSkillsList] = useState(ENTITY_SKILLS.map(skill => ({
    id: skill,
    name: skill,
  })))
  const [selectedSkill, setSelectedSkill] = useState('')

  const [selectedEntity, setSelectedEntity] = useState('')
  const [entitiesList, setEntitiesList] = useState([])

  // Setting modal title
  const [modalTitle, setModalTitle] = useState(false)

  // Setting modal icon
  const [modalIcon, setModalIcon] = useState(false)

  // Open modal form control to add/edit element
  const [modalTrigger, setModalTrigger] = useState(false)

  // Setting modal action enum[add,edit,delete]
  const [modalAction, setModalAction] = useState(false)

  // Fetch entities data
  const fetchEntities = async () => {
    try {
      const response = await EntitiesService.getEntities(1, '', 2000)
      setEntitiesList(response.data.entities)
    } catch (err) {
      setError(err)
    }
  }

  // Fetch groups data
  const fetchGroups = async () => {
    try {
      const response = await GroupsService.getGroups(currPage, search, perPage, sortBy, sortOrder, selectedSkill, selectedEntity)
      setGroups(response.data.groups)
      setTotalRows(response.data.pagination.totalItems)
      setIsLoaded(true)
    } catch (err) {
      setIsLoaded(false)
      setError(err)
    }
  }

  const handleModal = () => {
    setModalTrigger(false)
    updateFunction(true)
    fetchGroups()
  }

  const handleSelectSkillChange = (e) => {
    setSelectedSkill(e.target.value)
    setCurrPage(1)
  }

  const handleSelectEntityChange = (e) => {
    setSelectedEntity(e.target.value)
    setCurrPage(1)
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
    setCurrPage(1) // Reinicia a la primera página cuando se busca
  }

  const handleSearchClean = () => {
    setSelectedSkill('')
    setSelectedEntity('')
    setSearch('')
    setCurrPage(1) // Reinicia a la primera página cuando se busca
  }

  // fetch entities when start
  useEffect(() => {
    fetchEntities()
  }, [])

  // Re-fetch data on page/perPage change
  useEffect(() => {
    fetchGroups()
  }, [currPage, perPage, search, sortBy, sortOrder, selectedSkill, selectedEntity])

  const handleButtonClick = ({ data, type }) => {
    // Definir un objeto para mapear tipos a títulos e íconos
    const modalConfig = {
      add: {
        title: <FormattedMessage id="groupsAdd" defaultMessage="Add group" />,
        icon: <PlusCircle />,
      },
      edit: {
        title: <FormattedMessage id="groupsEdit" defaultMessage="Edit group" />,
        icon: <PencilSquare />,
      },
      delete: {
        title: <FormattedMessage id="groupsDelete" defaultMessage="Delete group" />,
        icon: <Trash />,
      }
    }

    // Establecer la configuración del modal dependiendo del tipo
    const config = modalConfig[type] || {}

    if (config) {
      setGroupId(type === 'add' ? false : data._id) // Si 'add', groupid será falso
      setModalTitle(config.title) // Set title from the config
      setModalIcon(config.icon) // Set icon from the config
      setModalAction(type)
      setModalTrigger(true)
    }
  }

  const handleRowsPerPageChange = (newPerPage, page) => {
    setPerPage(newPerPage)
    setCurrPage(page)
  }

  const handleSort = async (column, sortDirection) => {
    setSortOrder(sortDirection)
    setSortBy(column.id)
    setCurrPage(1)
  }

  const columns = [
    {
      id: 'name',
      name: <FormattedMessage id="groupsName" defaultMessage="Name" />,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      id: 'skill',
      name: <FormattedMessage id="groupsSkill" defaultMessage="Skill" />,
      selector: (row) => row.skill,
      sortable: true,
    },
    {
      id: 'eventId',
      name: <FormattedMessage id="groupsEvent" defaultMessage="Event" />,
      selector: (row) => row.event.name,
      sortable: true,
    },
    {
      id: 'formId',
      name: <FormattedMessage id="groupsForm" defaultMessage="Form" />,
      selector: (row) => row.form.title,
      sortable: true,
    },
    {
      id: 'entities',
      name: <FormattedMessage id="groupsEntities" defaultMessage="Entities" />,
      selector: (row) => <Overlay data={row.entities} />,
      right: true
    },
    {
      id: 'missions',
      name: <FormattedMessage id="groupsMissions" defaultMessage="Missions" />,
      selector: (row) => <Overlay data={row.missions} />,
      right: true
    },
    {
      id: 'createdAt',
      name: <FormattedMessage id="groupsCreatedAt" defaultMessage="Created at" />,
      selector: (row) => fromNow(row.createdAt),
      sortable: true,
    }
  ]


  return (
    <Container fluid className="p-0">
      <Row className="p-1 mt-3">
        <Col>
          <InputGroup>
            <InputGroup.Text><Stars /></InputGroup.Text>
            <SearchSelect
              fieldName={'skill'}
              selected={selectedSkill}
              onChange={handleSelectSkillChange}
              options={skillsList}
              placeholder={'entitiesSkillSelect'}
            />
            <InputGroup.Text><BriefcaseFill /></InputGroup.Text>
            <SearchSelect
              fieldName={'selectedEntity'}
              selected={selectedEntity}
              onChange={handleSelectEntityChange}
              options={entitiesList}
              placeholder={'usersEntityNameSelect'}
            />
            <InputGroup.Text><Search /></InputGroup.Text>
            <Form.Control
              type="text"
              placeholder={intl.formatMessage({ id: "textSearch", defaultMessage: "Search groups..." })}
              value={search}
              onChange={handleSearchChange}
            />
            <Button onClick={handleSearchClean} variant="light">
              <Backspace /> <FormattedMessage id="textClear" defaultMessage="Clear" />
            </Button>

            <Button onClick={() => handleButtonClick({ data: null, type: 'add' })} variant="primary">
              <PlusCircle /> <FormattedMessage id="textAdd" defaultMessage="Add" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      {isLoaded ? (
        <TableManagement
          icon={<Diagram3Fill />}
          title={<FormattedMessage id="navGroups" defaultMessage="Groups" />}
          columns={columns}
          data={groups}
          paginationPerPage={perPage}
          paginationTotalRows={totalRows}
          onChangePage={setCurrPage}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onSort={handleSort}
          onActionClick={handleButtonClick}
        />
      ) : error ? (
        <AlertError error={error} />
      ) : (
        <AlertLoading isLoaded={isLoaded} />
      )}

      <FormModal
        title={modalTitle}
        icon={modalIcon}
        body={GroupsForm}
        refreshData={handleModal}
        modalTrigger={modalTrigger}
        elementId={groupId}
        action={modalAction} />

    </Container>
  )
}

export default Groups