import React, { useState, useEffect } from "react"
import { useIntl, FormattedMessage } from "react-intl"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Search, PencilSquare, Trash, PlusCircle, Backspace, PeopleFill, PersonRolodex, BriefcaseFill } from "react-bootstrap-icons" // Iconos de Bootstrap

import { AlertError, AlertLoading } from '../helpers/AlertHelper'
import { fromNow } from "../helpers/momentHelper"

import FormModal from "../components/modals/FormModalNew"
import TableManagement from "../components/tables/TableManagement"
import Overlay from "../components/overlays/Overlay"
import SearchSelect from "components/form/SearchSelect"

import ConfigService from '../services/ConfigService'
import UsersService from "../services/UsersService"
import RolesService from "services/RolesService"
import EntitiesService from "services/EntitiesService"

import UsersForm from "./UsersForm"

const Users = () => {
  const intl = useIntl()

  const frontConfig = ConfigService.getFrontConfig()

  const [userId, setUserId] = useState(null)
  const [users, setUsers] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [search, setSearch] = useState("")
  const [sortBy, setSortBy] = useState("name")
  const [sortOrder, setSortOrder] = useState("asc")
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(frontConfig.app.itemsListLimit)

  const [selectedRole, setSelectedRole] = useState('')
  const [rolesList, setRolesList] = useState([])
  const [selectedEntity, setSelectedEntity] = useState('')
  const [entitiesList, setEntitiesList] = useState([])

  // Setting modal title
  const [modalTitle, setModalTitle] = useState(false)

  // Setting modal icon
  const [modalIcon, setModalIcon] = useState(false)

  // Open modal form control to add/edit element
  const [modalTrigger, setModalTrigger] = useState(false)

  // Setting modal action enum[add,edit,delete]
  const [modalAction, setModalAction] = useState(false)

  // Fetch users data
  const fetchUsers = async () => {
    try {
      const response = await UsersService.getUsers(currPage, search, perPage, sortBy, sortOrder, selectedRole, selectedEntity)
      setUsers(response.data.users)
      setTotalRows(response.data.pagination.totalItems)
      setIsLoaded(true)
    } catch (err) {
      setIsLoaded(false)
      setError(err)
    }
  }

  // Fetch roles data
  const fetchRoles = async () => {
    try {
      const response = await RolesService.getRoles()
      setRolesList(response.data.roles)
    } catch (err) {
      setError(err)
    }
  }

   // Fetch entities data
   const fetchEntities = async () => {
    try {
      const response = await EntitiesService.getEntities(1, '', 2000)
      setEntitiesList(response.data.entities)
    } catch (err) {
      setError(err)
    }
  }

  const handleModal = () => {
    setModalTrigger(false)
    fetchUsers()
  }

  const handleSelectRoleChange = (e) => {
    setSelectedRole(e.target.value)
    setCurrPage(1)
  }

  const handleSelectEntityChange = (e) => {
    setSelectedEntity(e.target.value)
    setCurrPage(1)
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
    setCurrPage(1) // Reinicia a la primera página cuando se busca
  }

  const handleSearchClean = () => {
    setSelectedRole('')
    setSelectedEntity('')
    setSearch('')
    setCurrPage(1) // Reinicia a la primera página cuando se busca
  }

  // fetch entities when start
  useEffect(() => {
    fetchRoles()
    fetchEntities()
  }, [])

  // Re-fetch data on page/perPage change
  useEffect(() => {
    fetchUsers()
  }, [currPage, perPage, search, sortBy, sortOrder, selectedRole, selectedEntity])

  const handleButtonClick = ({ data, type }) => {
    // Definir un objeto para mapear tipos a títulos e íconos
    const modalConfig = {
      add: {
        title: <FormattedMessage id="usersAdd" defaultMessage="Add user" />,
        icon: <PlusCircle />,
      },
      edit: {
        title: <FormattedMessage id="usersEdit" defaultMessage="Edit user" />,
        icon: <PencilSquare />,
      },
      delete: {
        title: <FormattedMessage id="usersDelete" defaultMessage="Delete user" />,
        icon: <Trash />,
      }
    }

    // Establecer la configuración del modal dependiendo del tipo
    const config = modalConfig[type] || {}

    if (config) {
      setUserId(type === 'add' ? false : data._id) // Si 'add', userid será falso
      setModalTitle(config.title) // Set title from the config
      setModalIcon(config.icon) // Set icon from the config
      setModalAction(type)
      setModalTrigger(true)
    }
  }

  const handleRowsPerPageChange = (newPerPage, page) => {
    setPerPage(newPerPage)
    setCurrPage(page)
  }

  const handleSort = async (column, sortDirection) => {
    setSortOrder(sortDirection)
    setSortBy(column.id)
    setCurrPage(1)
  }

  const columns = [
    {
      id: 'name',
      name: <FormattedMessage id="usersName" defaultMessage="Name" />,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      id: 'entityId',
      name: <FormattedMessage id="usersEntityName" defaultMessage="Entity name" />,
      selector: (row) => row.entity?.name || "No entity",
      sortable: true,
    },
    {
      id: 'phone',
      name: <FormattedMessage id="usersPhoneNumber" defaultMessage="Phone number" />,
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      id: 'email',
      name: <FormattedMessage id="usersEmail" defaultMessage="E-mail" />,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      id: 'roles',
      name: <FormattedMessage id="usersRoles" defaultMessage="Roles" />,
      selector: (row) => <Overlay data={row.roles} />,
      sortable: true,
      right: true,
    },
    {
      id: 'lastLoginAt',
      name: <FormattedMessage id="usersLastLogin" defaultMessage="Last login" />,
      selector: (row) => fromNow(row.lastLoginAt),
      sortable: true,
    },
    {
      id: 'createdAt',
      name: <FormattedMessage id="usersCreatedAt" defaultMessage="Created at" />,
      selector: (row) => fromNow(row.createdAt),
      sortable: true,
    }
  ]


  return (
    <Container fluid className="p-0">
      <Row className="p-1 mt-3">
        <Col>
          <InputGroup>
            <InputGroup.Text><PersonRolodex /></InputGroup.Text>
            <SearchSelect
              fieldName={'role'}
              selected={selectedRole}
              onChange={handleSelectRoleChange}
              options={rolesList}
              placeholder={'usersRoleSelect'}
            />
            <InputGroup.Text><BriefcaseFill /></InputGroup.Text>
            <SearchSelect
              fieldName={'selectedEntity'}
              selected={selectedEntity}
              onChange={handleSelectEntityChange}
              options={entitiesList}
              placeholder={'usersEntityNameSelect'}
            />
            <InputGroup.Text><Search /></InputGroup.Text>
            <Form.Control
              type="text"
              placeholder={intl.formatMessage({ id: "textSearch", defaultMessage: "Search users..." })}
              value={search}
              onChange={handleSearchChange}
            />
            <Button onClick={handleSearchClean} variant="light">
              <Backspace /> <FormattedMessage id="textClear" defaultMessage="Clear" />
            </Button>

            <Button onClick={() => handleButtonClick({ data: null, type: 'add' })} variant="primary">
              <PlusCircle /> <FormattedMessage id="textAdd" defaultMessage="Add" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      {isLoaded ? (
        <TableManagement
          icon={<PeopleFill />}
          title={<FormattedMessage id="navUsers" defaultMessage="Users" />}
          columns={columns}
          data={users}
          paginationPerPage={perPage}
          paginationTotalRows={totalRows}
          onChangePage={setCurrPage}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onSort={handleSort}
          onActionClick={handleButtonClick}
        />
      ) : error ? (
        <AlertError error={error} />
      ) : (
        <AlertLoading isLoaded={isLoaded} />
      )}

      <FormModal
        title={modalTitle}
        icon={modalIcon}
        body={UsersForm}
        refreshData={handleModal}
        modalTrigger={modalTrigger}
        elementId={userId}
        action={modalAction} />

    </Container>
  )
}

export default Users