import React, { useState, useEffect } from "react"
import { useIntl } from "react-intl"
import { Typeahead } from "react-bootstrap-typeahead"

const SearchSelect = ({
    fieldId = "id",
    fieldName,
    labelKey,
    selected,
    options,
    onChange,
    disabled,
    multiple,
    placeholder,
    required = false,
    isInvalid,
    isValid,
}) => {
    const intl = useIntl()
    const [selectedOptions, setSelectedOptions] = useState([])
    const [isFieldInvalid, setIsFieldInvalid] = useState(false)

    // Encontrar la selección inicial basada en `selected`
    const getInitialSelection = () => {
        if (multiple) {
            setIsFieldInvalid(required && selected.length === 0)
            return options.filter((option) => selected.includes(option[fieldId])) // Soporte para selección múltiple
        } else {
            setIsFieldInvalid(required && !selected)
            const selectedOption = options.find((option) => option[fieldId] === selected)
            return selectedOption ? [selectedOption] : []
        }
    }

    useEffect(() => {
        // Actualizar la selección inicial al cargar o si cambian `selected` o `options`
        setSelectedOptions(getInitialSelection())
    }, [selected, options])

    const handleSelectionChange = (selectedOption) => {
        if (multiple) {
            const selectedIds = selectedOption.map((item) => item[fieldId])
            onChange({
                target: { name: fieldName, value: selectedIds },
            })
        } else {
            if (selectedOption.length > 0) {
                const selectedItem = selectedOption[0]
                onChange({
                    target: { name: fieldName, value: selectedItem[fieldId] },
                })
            } else {
                onChange({ target: { name: fieldName, value: "" } })
                setSelectedOptions([])
            }
        }
        if (required) {
            setIsFieldInvalid(selectedOption.length === 0)  // Para múltiples, la longitud es 0 si no hay selección
        }
    }

    return (
        <Typeahead
            clearButton
            id={fieldName || "searchSelectTypeahead"}
            labelKey={labelKey || "name"}
            onChange={handleSelectionChange}
            options={options}
            placeholder={
                placeholder
                    ? intl.formatMessage({ id: placeholder, defaultMessage: placeholder })
                    : intl.formatMessage({
                        id: "textClickSelect",
                        defaultMessage: "Click to select or search",
                    })
            }
            selected={selectedOptions}
            disabled={!!disabled}
            multiple={!!multiple}
            isInvalid={isInvalid && !!isFieldInvalid }
            isValid={!!isValid}
            inputProps={{ required: !!required && !!isFieldInvalid }}
        />
    )
}

export default SearchSelect
