import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class FormsService {

  // Get forms list
  getForms = async (pageNum, search = '', limit = false, sortBy = 'title', sortOrder = 'asc', skillId = '') => {
    return await axios.get(API_URL + '/forms?page=' + pageNum + '&search=' + search + '&limit=' + limit + '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + '&skillId=' + skillId, { headers: authHeader() })
  }

  // Add new form
  addForm = async (form) => {
    return await axios.post(API_URL + '/forms', form, { headers: authHeader() })
  }

  // Update form data
  updateForm = async (form, id) => {
    return await axios.patch(API_URL + '/forms/' + id, form, { headers: authHeader() })
  }

  // Get form data
  getForm = async (id) => {
    return await axios.get(API_URL + '/forms/' + id, { headers: authHeader() })
  }

  // Delete form
  deleteForm = async (id) => {
    return await axios.delete(API_URL + '/forms/' + id, { headers: authHeader() })
  }

}

export default new FormsService()