import React, { useState, useEffect } from "react"
import { useIntl, FormattedMessage } from "react-intl"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Search, PencilSquare, Trash, PlusCircle, Backspace, FileCodeFill, Stars } from "react-bootstrap-icons" // Iconos de Bootstrap

import { AlertError, AlertLoading } from '../helpers/AlertHelper'
import { fromNow } from "../helpers/momentHelper"

import FormModal from "../components/modals/FormModalNew"
import TableManagement from "../components/tables/TableManagement"
import SearchSelect from "components/form/SearchSelect"

import ConfigService from '../services/ConfigService'
import FormsService from "../services/FormsService"

import { ENTITY_SKILLS } from '../data/entities'

import FormsForm from "./FormsForm"

const Forms = () => {
  const intl = useIntl()

  const frontConfig = ConfigService.getFrontConfig()

  const [eventId, setFormId] = useState(null)
  const [forms, setForms] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [search, setSearch] = useState("")
  const [sortBy, setSortBy] = useState("title")
  const [sortOrder, setSortOrder] = useState("asc")
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(frontConfig.app.itemsListLimit)

  const [skillsList, setSkillsList] = useState(ENTITY_SKILLS.map(skill => ({
    id: skill,
    name: skill,
  })))
  const [selectedSkill, setSelectedSkill] = useState('')

  // Setting modal title
  const [modalTitle, setModalTitle] = useState(false)

  // Setting modal icon
  const [modalIcon, setModalIcon] = useState(false)

  // Open modal form control to add/edit element
  const [modalTrigger, setModalTrigger] = useState(false)

  // Setting modal action enum[add,edit,delete]
  const [modalAction, setModalAction] = useState(false)

  // Fetch data
  const fetchForms = async () => {
    try {
      const response = await FormsService.getForms(currPage, search, perPage, sortBy, sortOrder, selectedSkill)
      setForms(response.data.forms)
      setTotalRows(response.data.pagination.totalItems)
      setIsLoaded(true)
    } catch (err) {
      setIsLoaded(false)
      setError(err)
    }
  }

  const handleModal = () => {
    setModalTrigger(false)
    fetchForms()
  }

  const handleSelectSkillChange = (e) => {
    setSelectedSkill(e.target.value)
    setCurrPage(1)
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
    setCurrPage(1) // Reinicia a la primera página cuando se busca
  }

  const handleSearchClean = () => {
    setSelectedSkill('')
    setSearch('')
    setCurrPage(1) // Reinicia a la primera página cuando se busca
  }

  // Re-fetch data on page/perPage change
  useEffect(() => {
    fetchForms()
  }, [currPage, perPage, search, sortBy, sortOrder, selectedSkill])

  const handleButtonClick = ({ data, type }) => {
    // Definir un objeto para mapear tipos a títulos e íconos
    const modalConfig = {
      add: {
        title: <FormattedMessage id="formsAdd" defaultMessage="Add event" />,
        icon: <PlusCircle />,
      },
      edit: {
        title: <FormattedMessage id="formsEdit" defaultMessage="Edit event" />,
        icon: <PencilSquare />,
      },
      delete: {
        title: <FormattedMessage id="formsDelete" defaultMessage="Delete event" />,
        icon: <Trash />,
      }
    }

    // Establecer la configuración del modal dependiendo del tipo
    const config = modalConfig[type] || {}

    if (config) {
      setFormId(type === 'add' ? false : data._id) // Si 'add', eventid será falso
      setModalTitle(config.title) // Set title from the config
      setModalIcon(config.icon) // Set icon from the config
      setModalAction(type)
      setModalTrigger(true)
    }
  }

  const handleRowsPerPageChange = (newPerPage, page) => {
    setPerPage(newPerPage)
    setCurrPage(page)
  }

  const handleSort = async (column, sortDirection) => {
    setSortOrder(sortDirection)
    setSortBy(column.id)
    setCurrPage(1)
  }

  const columns = [
    {
      id: 'title',
      name: <FormattedMessage id="formsTitle" defaultMessage="Title" />,
      selector: (row) => row.title,
      sortable: true,
    },
    {
      id: 'skill',
      name: <FormattedMessage id="formsSkill" defaultMessage="Skill" />,
      selector: (row) => row.skill,
      sortable: true,
    },
    {
      id: 'createdAt',
      name: <FormattedMessage id="formsCreatedAt" defaultMessage="Created at" />,
      selector: (row) => fromNow(row.createdAt),
      sortable: true,
    }
  ]

  return (
    <Container fluid className="p-0">
      <Row className="p-1 mt-3">
        <Col>
          <InputGroup>
            <InputGroup.Text><Stars /></InputGroup.Text>
            <SearchSelect
              fieldName={'skill'}
              selected={selectedSkill}
              onChange={handleSelectSkillChange}
              options={skillsList}
              placeholder={'entitiesSkillSelect'}
            />
            <InputGroup.Text><Search /></InputGroup.Text>
            <Form.Control
              type="text"
              placeholder={intl.formatMessage({ id: "textSearch", defaultMessage: "Search forms..." })}
              value={search}
              onChange={handleSearchChange}
            />
            <Button onClick={handleSearchClean} variant="light">
              <Backspace /> <FormattedMessage id="textClear" defaultMessage="Clear" />
            </Button>

            <Button onClick={() => handleButtonClick({ data: null, type: 'add' })} variant="primary">
              <PlusCircle /> <FormattedMessage id="textAdd" defaultMessage="Add" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      {isLoaded ? (
        <TableManagement
          icon={<FileCodeFill />}
          title={<FormattedMessage id="navForms" defaultMessage="Forms" />}
          columns={columns}
          data={forms}
          paginationPerPage={perPage}
          paginationTotalRows={totalRows}
          onChangePage={setCurrPage}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onSort={handleSort}
          onActionClick={handleButtonClick}
        />
      ) : error ? (
        <AlertError error={error} />
      ) : (
        <AlertLoading isLoaded={isLoaded} />
      )}

      <FormModal
        title={modalTitle}
        icon={modalIcon}
        body={FormsForm}
        refreshData={handleModal}
        modalTrigger={modalTrigger}
        elementId={eventId}
        action={modalAction} />

    </Container>
  )
}

export default Forms